import React, { FunctionComponent, useRef, useState } from "react"
import { graphql, Link } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination } from "swiper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

import "swiper/css"

import Button from "../components/Button"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

// @ts-ignore
import allCylinders from "../images/all-cylinders.gif"
import { Blog, Podcast, YoutubeVideo } from "../types"
import PodcastCard from "../components/PodcastCard"
import TypedText from "../components/TypedText"
import OnScreen from "../components/OnScreen"

interface Props {
  data: any
}

const NewLevels: FunctionComponent<Props> = ({ data }) => {
  const [activePodcastId, setActivePodcastId] = useState("")

  const podcastNextButton = useRef<HTMLButtonElement>(null)
  const videosNextButton = useRef<HTMLButtonElement>(null)
  const articleNext = useRef<HTMLButtonElement>(null)
  const podcastPrevButton = useRef<HTMLButtonElement>(null)
  const videosPrevButton = useRef<HTMLButtonElement>(null)
  const articlePrevious = useRef<HTMLButtonElement>(null)

  const blog = data.allContentfulBlog.edges.map(
    (elem: any) => elem.node
  ) as Blog[]
  const episodes = data.allContentfulPodcast.edges.map(
    (elem: any) => elem.node
  ) as Podcast[]
  const videos = data.allYoutubeVideo.edges.map(
    (elem: any) => elem.node
  ) as YoutubeVideo[]

  function formatDate(date: Date) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ]

    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
  }

  return (
    <Layout absoluteHeader>
      <SEO
        description="Check out my content library to nurture a growth mindset to reach new levels of success. New Levels New Devils - get yourself prepared!"
        title="New Levels | Moore Momentum"
        link="https://mooremomentum.com/new-levels/"
      />

      <div className="primary-gradient-bg overflow-hidden px-4 py-32 pb-64 -mb-48 sm:pt-40 md:pb-64 md:-mb-48 lg:-mb-32 lg:pb-32 lg:pt-48">
        <div className="max-w-full gap-8 grid grid-cols-1 items-center mx-auto text-white lg:grid-cols-2 lg:container lg:mb-32">
          <div className="px-4">
            <div className="max-w-sm mx-auto relative shadow-lg lg:max-w-max">
              <img
                alt="firing on all cylinders"
                className="bg-white relative rounded-md z-10"
                src={allCylinders}
              />

              <div className="absolute bg-coral h-24 -left-4 -top-4 w-24" />
              <div className="absolute bg-coral -bottom-4 h-24 -right-4 w-24" />
            </div>
          </div>

          <div className="mt-8 lg:mt-0">
            <h1 className="font-Orbitron mb-8 text-shadow text-4xl lg:text-5xl lg:leading-tight">
              New Levels
            </h1>

            <div className="space-y-4">
              <p>
                Check out my content library to ensure you're gaining momentum
                daily by gamifying your habits.
              </p>

              <p>
                My content is geared at replacing your failure habits with
                success habits revolving around the main areas of your life -{" "}
                <Link
                  to="/5-core-areas-of-life/"
                  className="text-coral underline"
                >
                  5 Cores
                </Link>{" "}
                - scientifically proven to lead to deep, long-term, soul-filling
                happiness : )
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-2xl mx-auto mb-8 px-4 text-center">
        <div className="mb-16">
          <TypedText
            className="font-Press-Start px-4 text-center text-coral text-3xl lg:py-8 lg:text-4xl xl:text-5xl"
            text="READY..."
          />
        </div>

        <h2 className="font-Orbitron mb-4 text-2xl sm:text-3xl">
          Gamify Your Life Podcast
        </h2>

        <p>
          My podcast includes over 150 episodes of growth-focused conversations
          with some of self-care&apos;s top experts. Pop in your ear buds and
          start listening today!
        </p>
      </div>

      <div className="max-w-full mx-auto px-4 lg:container">
        <Swiper
          breakpoints={{
            640: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
            1600: {
              slidesPerView: 4,
            },
          }}
          modules={[Navigation, Pagination]}
          navigation={{
            nextEl: podcastNextButton.current,
            prevEl: podcastPrevButton.current,
          }}
          onBeforeInit={({ params }) => {
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            params.navigation.prevEl = podcastPrevButton.current
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            params.navigation.nextEl = podcastNextButton.current
          }}
          pagination
          slidesPerView={1}
        >
          {episodes.map(elem => (
            <SwiperSlide className="p-4 swiper-slide-height" key={elem.id}>
              <PodcastCard
                activePodcastId={activePodcastId}
                key={elem.id}
                podcast={elem}
                onPodcastPlayStateChanged={setActivePodcastId}
              />
            </SwiperSlide>
          ))}

          <div className="my-4 space-x-4 text-center">
            <button
              className="bg-gray border-2 border-black h-12 rounded-full transition-opacity w-12 disabled:opacity-30 hover:opacity-70"
              ref={podcastPrevButton}
              type="button"
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>

            <button
              className="bg-gray border-2 border-black h-12 rounded-full transition-opacity w-12 disabled:opacity-30 hover:opacity-70"
              ref={podcastNextButton}
              type="button"
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
        </Swiper>

        <div className="mt-4 text-center">
          <Button href="/podcast" link="GATSBY">
            View All
          </Button>
        </div>
      </div>

      <div className="bg-gray mt-16 py-16">
        <div className="max-w-full mx-auto px-4 lg:container">
          <h2 className="font-Orbitron mb-8 text-center text-2xl sm:text-3xl">
            Will Moore - Gamify Your Life YouTube Channel
          </h2>

          <Swiper
            breakpoints={{
              640: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
              1600: {
                slidesPerView: 4,
              },
            }}
            modules={[Navigation, Pagination]}
            navigation={{
              nextEl: videosNextButton.current,
              prevEl: videosPrevButton.current,
            }}
            onBeforeInit={({ params }) => {
              // @ts-ignore
              // eslint-disable-next-line no-param-reassign
              params.navigation.prevEl = videosPrevButton.current
              // @ts-ignore
              // eslint-disable-next-line no-param-reassign
              params.navigation.nextEl = videosNextButton.current
            }}
            pagination
            slidesPerView={1}
          >
            {videos.map(elem => (
              <SwiperSlide className="p-4 swiper-slide-height" key={elem.id}>
                <div className="bg-white h-full p-4 rounded-md shadow-lg">
                  <a href={`https://youtube.com/watch?v=${elem.videoId}`}>
                    <GatsbyImage
                      alt={elem.title}
                      className="h-44 mb-4 shadow-lg lg:h-36 xl:h-48 2xl:h-44"
                      image={
                        elem.localThumbnail.childImageSharp.gatsbyImageData
                      }
                    />

                    <p className="mb-4 text-coral text-sm">
                      {`Published | ${formatDate(new Date(elem.publishedAt))}`}
                    </p>

                    <p className="font-bold">{elem.title}</p>
                  </a>
                </div>
              </SwiperSlide>
            ))}

            <div className="my-4 space-x-4 text-center">
              <button
                className="bg-gray border-2 border-black h-12 rounded-full transition-opacity w-12 disabled:opacity-30 hover:opacity-70"
                ref={videosPrevButton}
                type="button"
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>

              <button
                className="bg-gray border-2 border-black h-12 rounded-full transition-opacity w-12 disabled:opacity-30 hover:opacity-70"
                ref={videosNextButton}
                type="button"
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
          </Swiper>

          <div className="mt-4 text-center">
            <Button
              href="https://www.youtube.com/channel/UCBBnybi_zZquxqB9rZIP6Zw"
              link="DEFAULT"
            >
              View All
            </Button>
          </div>
        </div>
      </div>

      <div className="max-w-full mt-16 mx-auto px-4 lg:container">
        <h2 className="font-Orbitron mb-8 text-center text-2xl sm:text-3xl">
          Gamify Your Life Blog
        </h2>
        <Swiper
          breakpoints={{
            640: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
            1600: {
              slidesPerView: 4,
            },
          }}
          modules={[Navigation, Pagination]}
          navigation={{
            nextEl: articleNext.current,
            prevEl: articlePrevious.current,
          }}
          onBeforeInit={({ params }) => {
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            params.navigation.prevEl = articlePrevious.current
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            params.navigation.nextEl = articleNext.current
          }}
          pagination
          slidesPerView={1}
        >
          {blog.map(elem => (
            <SwiperSlide className="p-4 swiper-slide-height" key={elem.id}>
              <div className="bg-gray p-4 rounded-md shadow-lg">
                <Link to={`/blog/${elem.slug}`}>
                  <GatsbyImage
                    alt={elem.coverImage.description}
                    image={elem.coverImage.gatsbyImageData}
                  />

                  <div className="mt-4">
                    <h2 className="font-Orbitron mb-4 md:text-lg lg:text-xl">
                      {elem.title}
                    </h2>

                    <p>{formatDate(new Date(elem.datePublished))}</p>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
          ))}
          <div className="my-4 space-x-4 text-center">
            <button
              className="bg-gray border-2 border-black h-12 rounded-full transition-opacity w-12 disabled:opacity-30 hover:opacity-70"
              ref={articlePrevious}
              type="button"
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>

            <button
              className="bg-gray border-2 border-black h-12 rounded-full transition-opacity w-12 disabled:opacity-30 hover:opacity-70"
              ref={articleNext}
              type="button"
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
        </Swiper>
        <div className="mt-8 text-center">
          <Button href="/blog" link="GATSBY">
            View All
          </Button>
        </div>
      </div>

      <div className="container mx-auto px-4 py-16">
        <div className="gap-8 grid grid-cols-1 items-center mt-8 md:grid-cols-2">
          <div className="">
            <div className="space-y-4">
              <h2 className="font-Orbitron text-2xl md:text-3xl">
                Who is Will Moore?
              </h2>

              <p>
                Will Moore is an entrepreneur, speaker, life coach, and
                happiness expert. His mission is to gamify the process of
                personal wellness so that people find joy in becoming the
                happiest and best version of themselves. After achieving success
                in the business world, Will realized that money and happiness
                were two different things. After years of research and personal
                growth, he developed a gamification approach to replacing bad
                habits with success habits. He is now the owner of Moore
                Momentum and helps others find true happiness and life success.
              </p>
            </div>

            <div className="mt-8">
              <Button href="/why-will-moore/" link="GATSBY">
                Learn More
              </Button>
            </div>
          </div>

          <div className="max-w-md mx-auto">
            <StaticImage
              alt="Will Moore"
              placeholder="blurred"
              src="../images/contact-graphic.png"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulBlog(limit: 4, sort: { datePublished: DESC }) {
      edges {
        node {
          coverImage {
            description
            gatsbyImageData(layout: CONSTRAINED)
          }
          datePublished
          id
          slug
          title
        }
      }
    }
    allContentfulPodcast(limit: 9, sort: { publishedDate: DESC }) {
      edges {
        node {
          blurb
          coverImage {
            description
            gatsbyImageData(layout: CONSTRAINED)
          }
          id
          mediaUrl
          publishedDate
          slug
          title
        }
      }
    }
    allYoutubeVideo(limit: 9, sort: { publishedAt: DESC }) {
      edges {
        node {
          id
          localThumbnail {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
          publishedAt
          title
          videoId
        }
      }
    }
  }
`

export default NewLevels
