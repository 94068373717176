import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons"

import { Podcast } from "../types"

interface Props {
  activePodcastId: string
  podcast: Podcast
  onPodcastPlayStateChanged: (id: string) => void
}

const PodcastCard: FunctionComponent<Props> = props => {
  const [duration, setDuration] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [seekPosition, setSeekPosition] = useState(0)
  const [timeElapsed, setTimeElapsed] = useState("00:00:00")

  const { activePodcastId, podcast, onPodcastPlayStateChanged } = props

  const playerRef = useRef<HTMLAudioElement | null>(null)

  const formatTime = useCallback((time: number) => {
    const hours = Math.floor(time / 3600)
    const minutes = Math.floor((time - hours * 3600) / 60)
    const seconds = Math.floor(time - hours * 3600 - minutes * 60)

    return `${hours < 10 ? `0${hours}` : hours}:${
      minutes < 10 ? `0${minutes}` : minutes
    }:${seconds < 10 ? `0${seconds}` : seconds}`
  }, [])

  useEffect(() => {
    function onSeek(event: Event) {
      const target = event.target as HTMLAudioElement

      setSeekPosition((target.currentTime / target.duration) * 100)
      setTimeElapsed(formatTime(target.currentTime))
    }

    const player = playerRef.current

    if (player) {
      player.addEventListener("timeupdate", onSeek)
    }

    return () => {
      if (player) {
        player.removeEventListener("timeupdate", onSeek)
      }
    }
  }, [formatTime])

  useEffect(() => {
    if (playerRef.current && isPlaying && activePodcastId !== podcast.id) {
      playerRef.current.pause()

      setIsPlaying(false)
    }
  }, [activePodcastId, podcast, isPlaying])

  function onPlayClick() {
    if (playerRef.current) {
      setIsPlaying(!isPlaying)

      if (isPlaying) {
        playerRef.current.pause()
        onPodcastPlayStateChanged("")
      } else {
        playerRef.current.play()
        onPodcastPlayStateChanged(podcast.id)
      }
    }
  }

  function formatDate(date: Date) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ]

    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
  }

  function truncate(input: string) {
    if (input.length > 80) {
      return `${input.substring(0, 40)}...`
    }

    return input
  }

  return (
    <div
      className="bg-gray flex flex-col  p-4 rounded-md shadow-md"
      style={{ minHeight: "620px" }}
    >
      <div className="w-100 ">
        <Link to={`/podcast/${podcast.slug}`}>
          <GatsbyImage
            alt={podcast.coverImage.description}
            className="rounded-md "
            image={podcast.coverImage.gatsbyImageData}
            height={"220px"}
            style={{ height: "100%" }}
          />
        </Link>
      </div>

      <div className="flex flex-col mt-8">
        <div className="flex-1">
          <Link to={`/podcast/${podcast.slug}`}>
            <h2 className="font-Orbitron text-lg md:text-xl">
              {podcast.title}
            </h2>
          </Link>

          <p className="text-sm">
            {formatDate(new Date(podcast.publishedDate))}
          </p>

          <p className="mt-4 text-sm blurb">{podcast.blurb}</p>
        </div>
      </div>
    </div>
  )
}

export default PodcastCard
